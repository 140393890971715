<template>
  <v-app id="app">
    <notifications />
    <router-view />
  </v-app>
</template>

<script>
import localService from './services/localService';
import appInfoMixin from './mixins/appInfo'
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie'
import userflow from "userflow.js";
import { user_flow_token } from "@/config/constants";
import ReviewInformationModal from "@/components/Common/ReviewInformationModal.vue";

export default {
  mixins: [appInfoMixin],
  methods: {
    checkAndStoreCookie() {
      let domainDetails = this.getDomainDetails()
      let tokens = Cookies.get(domainDetails.subdomain)
      if (tokens) {
        let [email, accessToken, refreshToken] = tokens.split(' ')
        localStorage.setItem('userInfo', JSON.stringify({'access_token': accessToken, 'refresh_token': refreshToken}))
        let { subdomain, domain, type } = domainDetails;
        Cookies.remove(domainDetails.subdomain, {"domain": `.${domain}.${type}`})
        this.$store.dispatch("login/refreshToken", {email, refresh_token: refreshToken, redirect: true});
      }
    },
    checkIfUserIsLoggedIn() {
      if (localService.getItem('userInfo')) {
        const userInfo = localService.getItem('userInfo');
        const token = userInfo['access_token'];

        if (userInfo && token) {
          const user = userInfo;
          if (user_flow_token) {
            userflow.init(user_flow_token);
            userflow.identify(user.entity_id, {
              name: user.first_name + ' ' + user.last_name,
              email: user.email,
              role: user.role,
            });
          }
          this.$store.commit('login/setState', { key: 'currentUser', value: userInfo });
          return this.$store.commit('login/setState', { key: 'isLoggedIn', value: true });
        }

        return this.$store.commit('login/setState', { key: 'isLoggedIn', value: false });
      }
    },
    checkAccessTokenExpiry() {
      const userInfo = localService.getItem('userInfo')
      if (userInfo) {
        const token = userInfo['access_token'] || '';
        const access = jwtDecode(token);
        const nowInSecs = Date.now() / 1000;
        const isExpired = (access.exp - nowInSecs) < (60 * 7);
        if (isExpired) {
          this.$store.dispatch("login/refreshToken", {email: userInfo.email, refresh_token: userInfo.refresh_token});
          return;
        }
      }
    }
  },
  async created() {
    await this.checkAndStoreCookie();
    await this.checkIfUserIsLoggedIn();
    await this.checkAccessTokenExpiry();
    setInterval(() => {
      this.checkAccessTokenExpiry();
    }, 1000 * 6);
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/global.scss';
</style>
