import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from "../views/Login.vue";
import localService from '../services/localService';

import {
  checkValidity,
  accountVerified,
  checkRole
} from './general';

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path)
      } else {
        next();
      }
    }
  },
  {
    path: "/login/:token",
    name: "Session Login",
    component: () => import(/* webpackChunkName: "Confirmation" */ "../views/Confirmation.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        if(!accountVerified()) {
          next()
        } else {
          next(from.path)
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/reset-password/:token/:verify?",
    name: "Session Password Reset",
    component: () => import(/* webpackChunkName: "Session Password Reset" */ "../views/NewPassword.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path)
      } else {
        next();
      }
    }
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import(/* webpackChunkName: "Signup" */ "../views/Signup.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path)
      } else {
        next();
      }
    }
  },
  {
    path: "/invitation/signup/:token",
    name: "Invitation Signup",
    component: () => import(/* webpackChunkName: "Signup" */ "../views/Signup.vue"),
    beforeEnter(to, from, next) {
      const userInfo = localService.getItem("userInfo");
      if (userInfo) { // added this check for logout existing user
        localService.removeItem('userInfo');
      }
      next();
    }
  },
  {
    path: "/signup/thank-you/:email",
    name: "Signup Thanks",
    component: () => import(/* webpackChunkName: "Signup" */ "../views/SignupThankYou.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path)
      } else {
        next();
      }
    }
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: () => import(/* webpackChunkName: "Reset-Password" */ "../views/ResetPassword.vue"),
    beforeEnter(to, from, next) {
      if (checkValidity()) {
        next(from.path)
      } else {
        next();
      }
    }
  },
  {
    path: '/broker/rating_results/:organizationId/:email/:accessToken?',
    name: 'Auto Login Broker',
    meta: { resultView: true },
    component: () => import( /* webpackChunkName: "Broker Auto Login" */ "../views/BrokerAutoLogin.vue"),
    beforeEnter(to, from, next) {
      const userInfo = localService.getItem("userInfo");
      if (userInfo) { // added this check for logout existing user
        localService.removeItem('userInfo');
      }
      next();
    }
  },
  {
    path: "/admin/login/:accessToken",
    name: "Auto Login Admin",
    component: () => import(/* webpackChunkName: "Admin Auto Login" */ "../views/AdminAutoLogin.vue"),
    beforeEnter(to, from, next) {
      const userInfo = localService.getItem("userInfo");
      if (userInfo) { // added this check for logout existing user
        localService.removeItem('userInfo');
      }
      next();
    }
  },
  {
    path: "/verify-account",
    name: "Verify Account",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      if (!checkValidity() || accountVerified()) {
        next(from.path)
      } else {
        next();
      }
    },
    component: () => import(/* webpackChunkName: "Verify-Account" */ "../views/VerifyAccount.vue")
  },
  {
    path: '/',
    beforeEnter(to, from, next) {
      /*
        This will run if user is not logged in and
        tries to go to an authenticated page.
      */
      if (!checkValidity()) {
        return next('/login');
      }

      if(!accountVerified()) {
        return next('/verify-account');
      }
      /*
        This will run when both the above conditions
        are satisfied and take the user to it's specified page.
      */
      switch (checkRole()) {
        case "admin":
          next('/admin/applications');
        break;
        default:
          next('/organization');
        break;
      }
    }
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "Main" */ "../layout/Main.vue"),
  },
  {
    path: "/organization",
    component: () => import(/* webpackChunkName: "Main" */ "../layout/Main.vue"),
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      const isValid = checkValidity();
      if (isValid) {
        next();
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: ':organizationId?',
        name: 'Company Details',
        meta: { requiresAuth: true },
        component: () => import( /* webpackChunkName: "Company Details" */ "../views/CompanyDetails.vue")
      },
      {
        path: ':organizationId/revenue',
        name: 'Company Details Revenue',
        meta: { requiresAuth: true },
        component: () => import("../views/Revenue.vue")
      },
      {
        path: ':organizationId/technology_services_revenue',
        name: 'Technology Services Revenue',
        meta: { requiresAuth: true },
        component: () => import("../views/TechnologyServicesRevenue.vue")
      },
      {
        path: ':organizationId/professional_services_revenue',
        name: 'Professional Services Revenue',
        meta: { requiresAuth: true },
        component: () => import("../views/ProfessionalServicesRevenue.vue")
      },
      {
        path: ':organizationId/largest_clients_revenue',
        name: 'Largest Clients Revenue',
        meta: { requiresAuth: true },
        component: () => import("../views/LargestClientsRevenue.vue")
      },
      {
        path: ':organizationId/industry_segment_revenue',
        name: 'Industry Segment Revenue',
        meta: { requiresAuth: true },
        component: () => import("../views/IndustrySegmentRevenue.vue")
      },
      {
        path: ':organizationId/contracts',
        name: 'Contracts',
        meta: { requiresAuth: true },
        component: () => import("../views/Contracts.vue")
      },
      {
        path: ':organizationId/quality_control_process',
        name: 'Quality Control Process',
        meta: { requiresAuth: true },
        component: () => import("../views/QualityControlProcess.vue")
      },
      {
        path: ':organizationId/subcontracting',
        name: 'Subcontracting',
        meta: { requiresAuth: true },
        component: () => import("../views/Subcontracting.vue")
      },
      {
        path: ':organizationId/budget_personnel',
        name: 'Budget And Personnel',
        meta: { requiresAuth: true },
        component: () => import("../views/BudgetAndPersonnel.vue")
      },
      {
        path: ':organizationId/cyber_security/:cyberOrganizationId?',
        name: 'Cyber Security Details',
        meta: { requiresAuth: true },
        component: () => import( /* webpackChunkName: "Cyber Organization Details" */ "../views/CyberOrganizationDetails.vue")
      },
      {
        path: ':organizationId/cyber_security/:cyberOrganizationId/questionnaire/:screenName?',
        name: 'Questionnaire',
        meta: { requiresAuth: true },
        component: () => import( /* webpackChunkName: "Email Security" */ "../views/Questionnaire.vue")
      },
      {
        path: ':organizationId/:cyberOrganizationId/result',
        name: 'Results',
        meta: { requiresAuth: true, resultView: true },
        component: () => import( /* webpackChunkName: "Company Details" */ "../views/Results.vue")
      },
      {
        path: ':organizationId/:cyberOrganizationId/top-recommendation/:type?',
        name: 'Top Recommendation',
        meta: { requiresAuth: true, resultView: true },
        component: () => import( /* webpackChunkName: "Company Details" */ "../views/TopRecommendation.vue")
      }
    ]
  },
  {
    path: "/admin",
    component: () => import(/* webpackChunkName: "Main" */ "../layout/Main.vue"),
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      const isValid = checkValidity();
      if (isValid) {
        next();
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: "upload-application",
        name: "UploadApplication",
        meta: { requiresAuth: true, id: "upload-application" },
        component: () => import(/* webpackChunkName: "CreateSalesperson" */ "../views/Admin/Applications/UploadApplication.vue"),
      },
      {
        path: "applications",
        name: "Applications",
        meta: { requiresAuth: true, id: "applications" },
        component: () => import(/* webpackChunkName: "CreateSalesperson" */ "../views/Admin/Applications/Applications.vue"),
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
