import axios from '../plugins/axios'
import { getField, updateField } from "vuex-map-fields"
import { vm } from "../main";
export default {
  namespaced: true,
  state: () => ({
    isUploadingApplication: false,
    applicationsList: [],
    agentList: [],
    brokerList: [],
    brokerageList: [],
    customerList: [],
    applicationFilters: {
      searchedTerm: null,
      status: [],
      broker: [],
      brokerage: [],
      renewal: null
    },
    applicationSidePanel: false,
    selectedApplication: null,
    selectedClientUser: null
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    addNewCustomer(state, payload) {
      state.customerList.push(payload);
    },
    updateApplication(state, application) {
      let index = state.applicationsList.findIndex((e) => e.entity_id == application.entity_id);
      if(index > -1) {
        vm.$set(state.applicationsList, index, application);
      }
      if (application.entity_id === state.selectedApplication?.entity_id) {
        state.selectedApplication = application
      }
    },
    deleteApplication(state, applicationId) {
      let index = state.applicationsList.findIndex((e) => e.entity_id == applicationId);
      if(index > -1) {
        state.applicationsList.splice(index, 1);
      }
    }
  },
  actions: {
    /* Action that login in a user */
    async getApplications({ commit, dispatch, state}, payload) {
      const { data } = await axios.get('/application');
      if (!data.success) {
        return;
      }
      commit('setState', { key: 'applicationsList', value: data.applications });
      return data;
    },
    async getApplicationById({ commit }, applicationId) {
      const { data } = await axios.post(`/application/check_agent/${applicationId}`);
      if (!data.success) {
        return;
      }
      return data;
    },
    async getOrganizationClientUser({ commit, dispatch }, organizationId) {
      let orgId = organizationId;
      const { data } = await axios.get(`/organization/${orgId}/client_user`);
      if (!data.success) {
        return;
      }
      if(data.user) {
        commit('setState', { key: 'selectedClientUser', value: data['user'] });
      }
      return data;
    },
    async addNewCustomer({ commit, dispatch, state}, payload) {
      commit('addNewCustomer', payload);
    },
    async uploadApplication({ commit, dispatch, state}, files) {
      try {
        commit("setState", { key: 'isUploadingApplication', value: true });
        let formData = new FormData();
        [...files].forEach((file) => {
          formData.append("file", file);
        })
        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
        const { data } = await axios.post("/application/scan", formData, config);
        if (data.success) {
          dispatch(
              "showMessage",
              { message: 'File uploaded successfully', messageType: "success", messageTitle: "Success" },
              { root: true }
          );
        }
        commit("setState", { key: 'isUploadingApplication', value: false });
        return data;
      } catch (e) {
        dispatch('showMessage', { message: 'Server side error, please try again later.', messageType: 'error', messageTitle: 'Error' }, { root: true });
      }

    },
    async updateApplication({ commit, dispatch, state}, payload) {
      const { data } = await axios.put(`/application/${payload.applicationId}`, payload.formData);
      if (!data.success) {
        return;
      }
      commit('updateApplication', data.application );
    },
    async getUnlinkCustomerList({ commit, dispatch, state}, payload) {
      const {data} = await axios.get('/organization/unlinked');
      if (!data.success) {
        return;
      }
      commit('setState', {key: 'customerList', value: data.data});
    },
    async getBrokerList({ commit }) {
      const {data} = await axios.get('/brokerage/brokers');
      if (!data.success) {
        return;
      }
      commit('setState', {key: 'brokerList', value: data.data});
    },
    async getAgentsList({ commit }) {
      const {data} = await axios.get('/brokerage/agents');
      if (!data.success) {
        return;
      }
      commit('setState', {key: 'agentList', value: data.data});
    },
    async getBrokerageList({ commit }) {
      const {data} = await axios.get(`/brokerage`);
      if (!data.success) {
        return;
      }
      commit('setState', {key: 'brokerageList', value: data.brokerages});
    },
    async downloadApplication({ commit, dispatch, state}, applicationId) {
      const {data} = await axios.get(`/application/${applicationId}/download`);
      if (!data.success) {
        return;
      }
      return data.download_url;
    },
    async sendApplicationToWorkbench({ commit, dispatch, state}, applicationId) {
      const {data} = await axios.post(`/application/${applicationId}/send_to_workbench`);
      if (!data.success) {
        return;
      }
      commit('updateApplication', data.application );
    },
    async deleteApplication({ commit, dispatch, state}, applicationId) {
      const {data} = await axios.delete(`/application/${applicationId}`);
      if (!data.success) {
        return;
      }
      commit("deleteApplication", applicationId)
    },
    async sendInvite({ commit, dispatch }, payload) {
      let formData = Object.assign({}, payload);
      let applicationId = formData.application_id;
      let resendInvite = formData.resend_invite;
      delete formData.resend_invite;
      delete formData.application_id;
      let url = `/application/invite_agent/${applicationId}`;
      if(resendInvite) {
        url = `/application/reinvite_agent/${applicationId}`;
      }
      const {data} = await axios.post(url, formData);
      if (!data.success) {
        return;
      }
      commit('updateApplication', data.application );
    },
  },
  getters: {
    getField
  }
}
