<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="560"
  >
    <v-card class="px-2 py-4">
      <v-card-text>
        <div v-if="!selectedOption">
          <div class="my-2 d-flex align-center">
            <img :src="require('@/assets/images/check-decagram.png')" style="max-width: 24px;" class="mr-2" />
            <span class="outfit-bold font-700 text-24 text-light-black">Insurance Information Found</span>
          </div>
          <div class="outfit-regular font-400 text-16 text-grey pt-2">Our records indicate we have insurance information from a previous application submitted by your company. To streamline your experience, we've pre-filled this data for you. We ask that you review each section carefully to ensure the accuracy of the information and update any details as necessary.</div>
        </div>
        <div v-else>
          <div class="my-2 d-flex align-center">
            <img :src="require('@/assets/images/alert-icon.png')" style="max-width: 24px;" class="mr-2" />
            <span class="outfit-bold font-700 text-24 text-light-black">All Information will be deleted</span>
          </div>
          <div class="outfit-regular font-400 text-16 text-grey pt-2">All previously retrieved information will be removed. You will need to manually enter information on each page.</div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <div v-if="!selectedOption">
          <v-btn
            text
            class="outfit-bold text-12 font-700 mr-2 no-background-hover text-transform-none text-light-black"
            @click="selectedOption = 'start-with-blank'"
          >
            Start with a blank application
          </v-btn>
          <v-btn
            dense
            depressed
            rounded
            color="#000"
            class="outfit-bold text-12 font-700 light-grey-outlined text-transform-none"
            @click="handleReviewInformation"
          >
            <span class="white--text">Review Information</span>
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            text
            class="outfit-bold text-12 font-700 mr-2 no-background-hover text-transform-none text-light-black"
            @click="handleContinueToBlank"
          >
            Continue to Blank Application
          </v-btn>
          <v-btn
            dense
            depressed
            rounded
            color="#000"
            class="outfit-bold text-12 font-700 light-grey-outlined text-transform-none"
            @click="selectedOption = null"
          >
            <span class="white--text">Back</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapFields} from "vuex-map-fields";
  import {mapGetters} from "vuex";
  import {vm} from "@/main";
  import localService from "@/services/localService";

  export default {
    name: "ReviewInformationModal",
    props: ["dialog"],
    data() {
      return {
        selectedOption: null
      }
    },
    computed: {
      ...mapFields('login', [
        'currentUser'
      ]),
      ...mapFields("user", ["currentOrganizationId", "cyberOrganizationId", "questionnaire", "showReviewInfoAcceptBtn", "applicationScanRequest"])
    },
    methods: {
      async handleContinueToBlank() {
        if(this.questionnaire.entity_id) {
          await this.$store.dispatch("user/deleteQuestionnaire", {
            organization_id: this.currentOrganizationId,
            questionnaireId: this.questionnaire.entity_id
          });
        }
        if(this.cyberOrganizationId) {
          await this.$store.dispatch("user/deleteCyberSecurityOrganization", {
            organization_id: this.currentOrganizationId,
            cyber_organization_id: this.cyberOrganizationId
          });
        }
        
        if (this.applicationScanRequest.entity_id) {
          await this.$store.dispatch("user/updateScannedApplicationStatus", {
            entity_id: this.applicationScanRequest.entity_id,
            status: "New"
          });
        }

        await this.$store.dispatch("user/deleteOrganization", { organization_id: this.currentOrganizationId }).then(() => {
          window.location = '/organization';
        });
        this.currentUser.first_time_login = false;
        let userInfo = this.currentUser;
        localService.setItem('userInfo', userInfo);
      },
      handleReviewInformation() {
        this.showReviewInfoAcceptBtn = true;
        if(this.$route.path != '/organization') {
          this.$router.push('/organization');
        }
      }
    }

  }
</script>