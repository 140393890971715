<template>
  <div>
    <img :src="require(`@/assets/images/logos/${logoUrl}`)" v-if="logoUrl" :style="`max-height: ${maxHeight}`" />
  </div>
</template>

<script>
import appInfoMixin from "@/mixins/appInfo";
export default {
  props: {
    maxHeight: {
      default: '54px',
      type: String
    }
  },
  mixins: [ appInfoMixin ],
  computed: {
    logoUrl() {
      return this.getAppLogoUrl();
    }
  }
}
</script>
