import localService from "../services/localService";

/* THIS WILL GET THE USER INFORMATION AND TOKEN */
function getUserInformationAndToken() {
  if (localService.getItem('userInfo')) {
    return {
      token: localService.getItem('userInfo') ? localService.getItem('userInfo')['access_token'] : null,
      userInfo: localService.getItem('userInfo') ? localService.getItem('userInfo') : null,
    }
  } else return {};
}

/* THIS WILL CHECK IF USER IS LOGGED IN OR NOT */
function checkValidity() {
  if (getUserInformationAndToken()['token'] && getUserInformationAndToken()['userInfo'])
    return true;
  return false;
}

function accountVerified() {
  if(checkValidity()) {
    return getUserInformationAndToken()['userInfo']?.verified
  }
}

/* THIS WILL CHECK THE LOGGED IN USER'S ROLE */
function checkRole() {
  if (Object.keys(getUserInformationAndToken()).length > 0) {
    const { userInfo: { role } } = getUserInformationAndToken();
    return role.toLowerCase();
  } return {};
}

export {
  checkValidity,
  accountVerified,
  getUserInformationAndToken,
  checkRole
}