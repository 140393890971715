import {mapFields} from "vuex-map-fields";
export default {
  beforeCreate() {
    const appName = process.env.VUE_APP_APPLICATION_NAME;
    document.title = appName ? appName : '';
  },
  computed: {
    ...mapFields("user", [
      "supportedCompanies"
    ])
  },
  methods: {
    getAppTitle() {
      const appName = process.env.VUE_APP_APPLICATION_NAME;
      return appName ? appName : '';
    },
    getAppLogoUrl() {
      const supportedCompanies = this.supportedCompanies;
      let subdomain = this.getSubDomain();
      subdomain = subdomain.replace('test-', '') //To support the test environment.
      if (supportedCompanies.includes(subdomain)) {
        return subdomain + '.png';
      }
      return null;
    },
    getSubDomain() {
      let domainDetails = this.getDomainDetails();
      let subdomain = domainDetails.subdomain;
      return subdomain
    },
    getDomainDetails() {
      let result = {};
      let regexParse = new RegExp('([a-z\-0-9]{2,63})\.([a-z\.]{2,5})$');
      let urlParts = regexParse.exec(window.location.hostname);
      result.domain = urlParts[1];
      result.type = urlParts[2];
      result.subdomain = window.location.hostname.replace(result.domain + '.' + result.type, '').slice(0, -1);;
      return result;
    }
  }
}
