<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mt-6 mb-0 custom-error-details">
    <v-row>
      <v-col cols="12" md="12" class="py-0">
        <v-text-field
          v-model="email"
          type="email"
          placeholder="Email"
          name="login-email"
          solo
          rounded
          :outlined="outlined ? true : false"
          flat
          :rules="[
            v => !!v || 'Email is required.',
            v => !v || !!checkEmail(v) || 'E-mail must be valid'
          ]"
          required
          @focus="elevate = true"
          @blur="elevate = false"
          ref="email"
          data-cy="email"
          validate-on-blur
          v-on:keyup.enter="login"
          class="dark-grey"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="view === 'login'">
      <v-col cols="12" md="12" class="py-0">
        <v-text-field
          v-model="password"
          :type="passwordType"
          placeholder="Password"
          solo
          rounded
          flat
          name="login-password"
          :outlined="outlined ? true : false"
          :rules="[
            v => !!v || 'Password is required.',
            (v) => validatePassword(v)
          ]"
          required
          @focus="elevate = true"
          @blur="elevate = false"
          data-cy="password"
          v-on:keyup.enter="login"
          class="dark-grey"
        >
          <template #append>
            <v-tooltip right color="black">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"  @click="showPassword" color="#000" dark class="cursor-pointer">
                  {{ passwordType === 'text' ? 'mdi-eye-off-outline': 'mdi-eye-outline'}}
                </v-icon>
              </template>
              <span class="text--white">
                {{ passwordType === 'text' ? 'Hide password': 'Show password'}}
              </span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="py-0">
        <v-btn
          :loading="isLoading"
          block
          depressed
          rounded
          class="btn-primary text-14 font-700"
          data-cy="login-button"
          :disabled="!loginFormValid"
          @click="login"
        >
          <strong>{{ view === 'login' ? 'Login' : 'Submit' }}</strong>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import isEmail from 'validator/lib/isEmail'
import validationMixin from '@/mixins/validation'
import isStrongPassword from "validator/lib/isStrongPassword";

export default {
  mixins: [validationMixin],
  props: ['outlined'],
  data: () => ({
    valid: false,
    elevate: false
  }),
  computed: {
    ...mapGetters("login", [
      'isLoading'
    ]),
    ...mapFields("login", [
      'view',
      'email',
      'password',
      'passwordType',
      'mfa_dialog',
      'twoFactor',
      'recoveryCodeForm',
      'authCode',
      'recoveryCode'
    ]),
    loginFormValid() {
      if(this.email == '' ||
        !isEmail(this.email) ||
        (this.password == '' && this.view == 'login')
      ) {
        return false
      }
      return true
    }
  },
  methods: {
    showPassword() {
      if (this.passwordType === 'text') {
        this.passwordType = 'password';
      } else {
        this.passwordType = 'text'
      }
    },
    validatePassword(value) {
      let condition =
          "Minimum of 8 characters, uppercase letters, numbers and special character are required.";
      // Password must be 8 characters long and include one number, one uppercase letter and one lowercase letter
      // first check if it's at least 8 characters long
      if (!value) {
        return "Password is required.";
      } else if (value.length < 8) {
        return condition;
      }

      if(!isStrongPassword(value)) {
        return condition;
      }

      return true;
    },
    getLoginCredentials() {
      return {
        email: this.email.trim(),
        password: this.password
      }
    },
    login() {
      if (this.view === 'forgot' && this.checkEmail(this.email)) {
        return this.reset()
      }

      if (this.$refs.form.validate()) {
        this.$store.dispatch('login/login', this.getLoginCredentials()).then((res) => {
        })
      }
    },
    async reset() {
      const reset_password = await this.$store.dispatch('login/resetPassword', { email: this.email })
      if (reset_password) {
        this.email = ''
        this.password = ''
        this.$emit('updateView', 'login')
        this.$router.push('/login')
      }
    }
  }
}
</script>
