import axios from 'axios';
import localService from '../services/localService';
import { vm } from '../main';
const startLoading= () => {
  vm.$store.commit("startLoading", null, { root: true })
}
const stopLoading = () => {
  vm.$store.commit("stopLoading", null, { root: true })
}

const notifyIfError = (response) => {
  const {data} = response;
  if (!data?.success && response?.request?.responseType !== "blob") {
    vm.$store.dispatch("showMessage", { ...data, messageType: "error", messageTitle: "Error" }, { root: true })
  }
}

const GET_API_URL = () => {
  return process.env.VUE_APP_API_URL;
}

const options = {
  baseURL: GET_API_URL(),
  headers: {
    'Content-Type': 'application/json',
  }
};

const instance = axios.create({ ...options });

instance.interceptors.request.use((config) => {
  if(config.method != 'patch') {
    startLoading();
  }
  const token = localService.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use((response) => {
  stopLoading();
  notifyIfError(response);
  return response;
}, (error) => {
  stopLoading();
  if (error.response.status === 401) {
    localService.clear();
    vm.$router.push({ path: '/login' });
  }
  return Promise.reject(error);
});

export default instance;
