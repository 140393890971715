var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",staticClass:"mt-6 mb-0 custom-error-details",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{ref:"email",staticClass:"dark-grey",attrs:{"type":"email","placeholder":"Email","name":"login-email","solo":"","rounded":"","outlined":_vm.outlined ? true : false,"flat":"","rules":[
          v => !!v || 'Email is required.',
          v => !v || !!_vm.checkEmail(v) || 'E-mail must be valid'
        ],"required":"","data-cy":"email","validate-on-blur":""},on:{"focus":function($event){_vm.elevate = true},"blur":function($event){_vm.elevate = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),(_vm.view === 'login')?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{staticClass:"dark-grey",attrs:{"type":_vm.passwordType,"placeholder":"Password","solo":"","rounded":"","flat":"","name":"login-password","outlined":_vm.outlined ? true : false,"rules":[
          v => !!v || 'Password is required.',
          (v) => _vm.validatePassword(v)
        ],"required":"","data-cy":"password"},on:{"focus":function($event){_vm.elevate = true},"blur":function($event){_vm.elevate = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"cursor-pointer",attrs:{"color":"#000","dark":""},on:{"click":_vm.showPassword}},on),[_vm._v(" "+_vm._s(_vm.passwordType === 'text' ? 'mdi-eye-off-outline': 'mdi-eye-outline')+" ")])]}}],null,false,825561636)},[_c('span',{staticClass:"text--white"},[_vm._v(" "+_vm._s(_vm.passwordType === 'text' ? 'Hide password': 'Show password')+" ")])])]},proxy:true}],null,false,686916822),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"btn-primary text-14 font-700",attrs:{"loading":_vm.isLoading,"block":"","depressed":"","rounded":"","data-cy":"login-button","disabled":!_vm.loginFormValid},on:{"click":_vm.login}},[_c('strong',[_vm._v(_vm._s(_vm.view === 'login' ? 'Login' : 'Submit'))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }