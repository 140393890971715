import axios from '../plugins/axios'
import { getField, updateField } from "vuex-map-fields"
import localService from '../services/localService'
import { vm } from '../main'
import appInfo from '../mixins/appInfo'
import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: () => ({
    isLoading: false,
    currentUser: {},
    isLoggedIn: false,
    email: '',
    password: '',
    confirmPassword: '',
    view: 'login',
    valid: false,
    elevate: false,
    passwordType: 'password',
    cPasswordType: 'password'
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    resetState(state) {
      state.currentUser = {}
      state.isLoggedIn = false
      state.email = ''
      state.password = ''
      state.confirmPassword = ''
      state.view = 'login'
      state.valid = false
      state.elevate = false
      state.passwordType = 'password'
      state.cPasswordType = 'password'
    }
  },
  actions: {
    /* Action that login in a user */
    async login({ commit, dispatch, state}, payload) {
      const { data } = await axios.post('/authentication/login', payload);
      if (!data.success) {
        return;
      }

      if (data.user.subdomain && window.location.hostname !== 'localhost') {
        let userSubdomain = data.user.subdomain;
        let { subdomain, domain, type } = appInfo.methods.getDomainDetails();
        if (userSubdomain !== subdomain) {
          Cookies.set(userSubdomain, `${data.user.email} ${data.user.access_token} ${data.user.refresh_token}`, {"domain": `.${domain}.${type}`})
          window.location.hostname = `${userSubdomain}.${domain}.${type}`
          return;
        }
      }

      let userInfo = data.user;
      commit('setState', { key: 'currentUser', value: userInfo });
      localService.setItem('userInfo', userInfo);
      localService.setItem('token', userInfo.access_token);
      commit('setState', { key: 'isLoggedIn', value: true });
      if(userInfo && !userInfo.verified) {
        dispatch('showMessage', { message: 'Your account is not verified!', messageType: 'error', messageTitle: 'Error' }, { root: true });
        vm.$router.go('/verify-account')
      } else {
        let user = Object.assign({}, userInfo);
        user.forceRedirectToCurrentScreen = true;
        commit('setState', { key: 'currentUser', value: user });
        localService.setItem('userInfo', user);
        vm.$router.go('/organization');
      }
    },
    async autoLogin({ commit, dispatch, state}, payload) {
      try {
        let reqInstance = axios.create({});
        const { data } = await reqInstance.get('/authentication/me', {
          headers: {
            "Authorization": `Bearer ${payload.token}`
          }
        });
        if (!data.success) {
          return;
        }
        let user = Object.assign({}, data.user);
        user.forceRedirectToCurrentScreen = true;
        commit('setState', { key: 'currentUser', value: user });
        localService.setItem('userInfo', user);
        localService.setItem('token', user.access_token);
        commit('setState', { key: 'isLoggedIn', value: true });
        if(payload.redirectUrl) {
          vm.$router.push(`/${payload.redirectUrl}`);
        } else {
          vm.$router.push('/organization');
        }
      } catch(e) {
        dispatch('showMessage', { message: 'Invalid token.', messageType: 'error', messageTitle: 'Error' }, { root: true });
        dispatch('logout');
      }
    },
    async confirmEmail({ commit, dispatch}, payload) {
      const { data } = await axios.post('/authentication/verify_email/'+payload.token)
      if(!data.success) {
        vm.$router.push({ path: '/login' });
        return;
      }
      dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });

      let userInfo = localService.getItem("userInfo")
      if(userInfo) { // for already loggedIn user - from verify account screen
        userInfo.verified = true;
        userInfo.first_time_login = true;
        localService.setItem("userInfo", userInfo)
        commit('setState', { key: 'currentUser', value: userInfo });
        vm.$router.push({ path: '/' });
      } else {
        vm.$router.push({ path: '/reset-password/'+data.reset_token+'/update' });
      }
    },
    async refreshToken({ commit, rootState }, payload) {
      let reqInstance = axios.create({});
      const { data } = await reqInstance.post('/authentication/refresh_token', {email: payload.email}, {
        headers: {
          "Authorization": `Bearer ${payload.refresh_token}`
        }
      });
      if (!data.success) {
        return;
      }
      commit('setState', { key: 'currentUser', value: data.user });
      localService.setItem('userInfo', data.user);
      localService.setItem('token', data.user.access_token);
      commit('setState', { key: 'isLoggedIn', value: true });
      if (payload.redirect) {
        if(data.user && !data.user.verified) {
          dispatch('showMessage', { message: 'Your account is not verified!', messageType: 'error', messageTitle: 'Error' }, { root: true });
          vm.$router.go('/verify-account')
        } else {
          let user = Object.assign({}, data.user);
          user.forceRedirectToCurrentScreen = true;
          commit('setState', { key: 'currentUser', value: user });
          localService.setItem('userInfo', user);
          vm.$router.go('/organization');
        }
      }
    },
    /* Action that logs out a user */
    async logout({ commit, rootState }) {
      await axios.post(`/authentication/logout`);
      commit('setState', { key:'isLoggedIn', value: false });
      commit('setState', { key: 'currentUser', value: {} });
      commit('resetState')
      localService.removeItem('userInfo');
      localService.removeItem('isClientFormFilled');
      vm.$router.push({ path: '/login' });
    },
    async resetPasswordUsingToken({ commit, dispatch }, payload) {
      const { token, password } = payload;
      const { data } = await axios.post(`/authentication/reset_password/${token}`, { password: password });
      if (!data.success) {
        return;
      }
      dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      let userInfo = data.user;
      if(payload.newAccount) {
        userInfo.verified = true;
        userInfo.first_time_login = true;
      }
      localService.setItem('userInfo', userInfo);
      localService.setItem('token', userInfo.access_token);
      commit('setState', { key: 'currentUser', value: userInfo });
      commit('setState', { key: 'isLoggedIn', value: true });
      if(data.user && !data.user.verified) {
        dispatch('showMessage', { message: 'Your account is not verified!', messageType: 'error', messageTitle: 'Error' }, { root: true });
        vm.$router.go('/verify-account')
      } else {
        let user = Object.assign({}, data.user);
        user.forceRedirectToCurrentScreen = true;
        commit('setState', { key: 'currentUser', value: user });
        localService.setItem('userInfo', user);
        vm.$router.go('/organization');
      }
    },
    async resendVerificationEmail({ commit, dispatch }, payload) {
      const { email } = payload;
      const { data } = await axios.post(`/authentication/resend_email_verification`, { email: email });
      if (!data.success) {
        return;
      }
      dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
    },
    /* Action that resets a password */
    async resetPassword({ commit, dispatch }, payload) {
      const { data } = await axios.post('/authentication/forgot_password', payload);
      if (!data.success) {
        return;
      }
      dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return data;
    },
    async signup({ commit, dispatch}, payload) {
      let userRole = payload.role ? payload.role : 'client';
      let url = `/authentication/register/${userRole}`;
      delete payload.role;
      const { data } = await axios.post(url, payload);
      if (!data.success) {
        return ;
      }
      localService.removeItem('cbr_signUpUserInfo');
      vm.$router.push({ path: `/signup/thank-you/${payload.email}` });
    },
    async invitationSignup({ commit, dispatch}, payload) {
      let token = payload.token;
      delete payload.token;
      let url = `/authentication/invitation/signup/${token}`;
      const { data } = await axios.post(url, payload);
      if (!data.success) {
        return ;
      }
      localService.removeItem('cbr_signUpUserInfo');
      vm.$router.push({ path: `/reset-password/${data.reset_token}` });
    },
  },
  getters: {
    getField,
    currentUser: state => state.currentUser,
    isLoading: state => state.isLoading,
    isLoggedIn: state => state.isLoggedIn,
    clientRole: state => state.currentUser?.user?.client_role // using only when current logged in user is client
  }
}
