import axios from '../plugins/axios'
import { getField, updateField } from "vuex-map-fields"
export default {
  namespaced: true,
  state: () => ({
    showReviewInfoAcceptBtn: false,
    supportedCompanies: [
      'assured-partners', 'bolton', 'cac-specialty', 'clark', 'cobbsallen', 'epic', 'hub', 'ima-financial', 'insuretrust',
      'integra', 'irm', 'lockton', 'lowa', 'marshmma','mcgriff', 'moody', 'oneill', 'palmerandcay', 'propel', 'psfinc',
      'rcmd', 'sspins', 'tbmins', 'tga-ins', 'usi', 'uwib', 'woodruff-sawyer', 'york'
    ],
    companyDetails: {
      name_of_insured: "",
      point_of_contact_email: null,
      address: "",
      city: "",
      zipcode: "",
      websites: [],
      naics_code: "",
      naics_description: "",
      policy_renewal_date: null,
      list_company_not_subsidiary_of_insured_covered_under_policy: null,
      it_budget: null,
      cyber_security_it_budget: null,
      full_time_employees: null,
      cyber_security_full_time_employees: null,
      outsource_network_security: false,
      insurance_EO: false
    },
    companyRevenueDetails: {
      month_day_revenue_fiscal_year_ends: null,
      previous_year_total_gross_revenue_ending: null,
      current_year_total_gross_revenue_ending: null,
      next_year_total_gross_revenue_ending: null,
      brief_description_of_insured_business_activities_services: null
    },
    technologyServicesRevenue: {
      application_service_provider: null,
      data_storage_hosting: null,
      development_sale_of_custom_software: null,
      development_sale_of_prepackaged_software: null,
      hardware_software_systems_sales_and_installation: null,
      hardware_component_manufacturing: null,
      information_technology_consulting: null,
      internet_service_provider: null,
      managed_services_or_managed_security_services_provider: null,
      point_of_sale_payment_processing: null,
      software_game_gaming_development: null,
      staffing_contract_information_technology_services: null,
      systems_integration: null,
      telecommunications_services: null,
      website_design_development_hosting: null,
      other: null,
      other_description: null
    },
    professionalServicesRevenue: [
      {
        revenue: null,
        describe_service: null
      },
      {
        revenue: null,
        describe_service: null
      },
      {
        revenue: null,
        describe_service: null
      },
      {
        revenue: null,
        describe_service: null
      }
    ],
    largestClientsRevenue: [
      {
        client_name: null,
        revenue: null,
        services_provided: null
      },
      {
        client_name: null,
        revenue: null,
        services_provided: null
      },
      {
        client_name: null,
        revenue: null,
        services_provided: null
      },
      {
        client_name: null,
        revenue: null,
        services_provided: null
      },
      {
        client_name: null,
        revenue: null,
        services_provided: null
      }
    ],
    industrySegmentRevenue: {
      aerospace_defense: null,
      automobiles_components: null,
      chemical: null,
      construction_engineering: null,
      consumer_services: null,
      electrical_equipment: null,
      energy_equipment_services: null,
      financial_services: null,
      healthcare: null,
      information_technology: null,
      manufacturing: null,
      media: null,
      oil_gas_utilities: null,
      retail: null,
      telecommunication: null,
      transportation: null,
      other: null,
      other_description: null
    },
    qualityControlProcess: {
      alpha_testing: null,
      beta_testing: null,
      business_continuity_plan: null,
      customer_screening_process: null,
      customer_support_by_telephone: null,
      customer_support_by_web_portal: null,
      customer_support_by_email_text: null,
      formalized_training_for_new_employees: null,
      prototyping_with_testing: null,
      vendor_certification_management_procedures: null,
      written_quality_control_standards_procedures: null,
      other: null,
      other_description: null
    },
    contracts: {
      insured_require_use_of_written_contracts_for_all_engagements: null,
      insured_standard_contracts_percentage: null,
      customer_contracts_percentage: null,
      customized_combination_percentage: null,
      disclaimer_of_warranties: null,
      alternative_dispute_resolution: null,
      exclusion_of_consequential_damages: null,
      exclusive_remedies_for_breach: null,
      force_majeure: null,
      indemnification_hold_harmless: null,
      limitation_of_liability: null,
      performance_milestones: null,
      statement_of_work: null,
      choice_of_law_or_venue: null,
      subcontracting: {
        insured_sub_contract_any_professional_services_manufacturing_fulfill_commitments_to_clients_customers: null,
        insured_require_sub_contractors_vendors_maintain_curren_in_force_errors_omissions_insurance_naming_insured_as_additional_insured_indemnitee: null,
        any_additional_information_pertaining__technology_professional_services_clients_industry_revenue_contracting_quality_control_subcontracting: null
      }
    },
    currentOrganizationId: null,
    applicationScanRequest: null,
    cyberOrgDetails: {
      outsource_network_security: false,
      point_of_contact: null,
      point_of_contact_email: null,
      point_of_contact_title: null
    },
    cyberOrganizationId: null,
    emailServiceControls: {
      screen_attachments: 'Screening for malicious attachments',
      screen_links: 'Screening for malicious links',
      quarantine_suspicious: 'Quarantine service on suspicious emails',
      evaluate_attachments: 'Detonation and evaluation of attachments in sandbox',
      tag_external: 'Tagging of external emails',
      employees_trained_external_email: 'Employees are trained to place extra scrutiny on attachments and links that come from external emails',
      dkim: 'Domain Keys Identified Mail (DKIM)',
      spf: 'Sender policy framework (SPF) strictly enforced',
      dmarc: 'Domain based messaging authentication, reporting and confirmation (DMARC)',
      email_filtering: 'We use additional email filtering tool(s)',
      web_filtering: 'We use Web Filtering to block access to known malicious websites',
      disable_macros: 'We disable macros in their office productivity software by default',
      user_macros: 'Users are allowed to enable macros',
      insured_have_secure_email_gateway: 'Does the insured have a secure email gateway?'
    },
    nonQuestionnaireScreenNames: [
      'revenue',
      'technology_services_revenue',
      'professional_services_revenue',
      'largest_clients_revenue',
      'industry_segment_revenue',
      'quality_control_process',
      'contracts',
      'budget_personnel',
      'cyber_security'
    ],
    screenNames: [
      'revenue',
      'technology_services_revenue',
      'professional_services_revenue',
      'largest_clients_revenue',
      'industry_segment_revenue',
      'quality_control_process',
      'contracts',
      'budget_personnel',
      'cyber_security',
      'media_content_confidential_data',
      'e_crime_controls',
      'prior_claims_experience_incidents',
      'email_security_services',
      'email_security_services_controls',
      'email_security_services_phishing',
      'physical_location_infrastructure',
      'remote_work_policy',
      'network_security',
      'network_security_capabilities',
      'network_security_capabilities_options',
      'general_security_controls',
      'on_premise_infrastructure',
      'legacy_systems',
      'cloud_based_applications_infrastructure',
      'backups_business_continuity',
      'backup_business_continuity_controls',
      'service_accounts',
      'vulnerabilities_scanning',
      'final_result'
    ],
    screenCatNames: {
      'Media Content and Confidential Data' : 'media_content_confidential_data',
      'eCrime Controls' : 'e_crime_controls',
      'Prior Claims Experience or Incidents' : 'prior_claims_experience_incidents',
      'Email Security': 'email_security_services',
      'Network Security': 'network_security',
      'Cloud Based Application(s) and Infrastructure': 'cloud_based_applications_infrastructure',
      'Backups & Business Continuity': 'backups_business_continuity',
      'Network Security Capabilities': 'network_security_capabilities',
      'General Security Capabilities': 'general_security_controls',
      'Legacy Systems': 'legacy_systems',
      'Service Accounts': 'service_accounts',
      'Vulnerability and Scanning': 'vulnerabilities_scanning'
    },
    screenNamesForEdit: {
      media_content_confidential_data: [
        'media_content_confidential_data'
      ],
      e_crime_controls: [
        'e_crime_controls'
      ],
      prior_claims_experience_incidents: [
        'prior_claims_experience_incidents'
      ],
      email_security_services: [
        'email_security_services',
        'email_security_services_controls'
      ],
      email_security_services_phishing: [
        'email_security_services_phishing'
      ],
      physical_location_infrastructure: [
        'physical_location_infrastructure'
      ],
      remote_work_policy: [
        'remote_work_policy'
      ],
      network_security: [
        'network_security'
      ],
      network_security_capabilities: [
        'network_security_capabilities',
        'network_security_capabilities_options',
        'general_security_controls'
      ],
      on_premise_infrastructure: [
        'on_premise_infrastructure'
      ],
      legacy_systems: [
        'legacy_systems'
      ],
      cloud_based_applications_infrastructure: [
        'cloud_based_applications_infrastructure'
      ],
      backups_business_continuity: [
        'backups_business_continuity',
      ],
      backup_business_continuity_controls: [
        'backup_business_continuity_controls'
      ],
      service_accounts: [
        'service_accounts'
      ],
      vulnerabilities_scanning: [
        'vulnerabilities_scanning'
      ]
    },
    shortFormScreenNames: [
      'revenue',
      'budget_personnel',
      'media_content_confidential_data',
      'e_crime_controls',
      'prior_claims_experience_incidents',
      'email_security_services',
      'email_security_services_phishing',
      'network_security',
      'backups_business_continuity',
      'final_result'
    ],
    shortFormScreenNamesForEdit: {
      media_content_confidential_data: [
        'media_content_confidential_data'
      ],
      e_crime_controls: [
        'e_crime_controls'
      ],
      prior_claims_experience_incidents: [
        'prior_claims_experience_incidents'
      ],
      email_security_services: [
        'email_security_services',
      ],
      email_security_services_phishing: [
        'email_security_services_phishing'
      ],
      network_security: [
        'network_security'
      ],
      backups_business_continuity: [
        'backups_business_continuity',
      ]
    },
    questionnaire: {
      form_version: "full",
      insurance_EO: false,
      current_screen: null,
      media_content_confidential_data: {
      },
      e_crime_controls: {
      },
      prior_claims_experience_incidents: {
      },
      email_security: {
        services: {
          others: [{
            name: null,
            website: null
          }],
          controls: {
            email_filtering: 'No',
            phishing_training: null
          }
        }
      },
      physical_location_security: {
        remote_work: {
        },
        network_security: {
          capabilities: {
          }
        },
        general_security: {
        }
      },
      on_prem_infrastructure: {
        legacy_systems: {
        }
      },
      general_security: {},
      cloud: {
      },
      backups: {
      },
      service_accounts: {
      },
      scanning: {
      }
    },
    enabledEditAnswer: false,
    ratingResults: null,
    defaultQuestionValueByKeyName: {
      media_content_confidential_data: {
        company_actively_screens_website_content_disparagement_intellectual_property_infringement_privacy_before_publishing: 'No',
        company_has_established_procedures_for_editing_removing_potentially_libelous_material_controversial_material_infringes_content_property_rights_others: 'No',
        social_security: null,
        financial_data_including_pci: null,
        protected_health_information: null,
        other_pii: null
      },
      e_crime_controls: {
        confirm_changes_by_call_to_predetermined_number: 'No',
        send_written_notice_to_person_at_vendor_supplier_other_than_who_made_request: 'No',
        require_review_all_change_requests_by_supervisor_approver: 'No',
        other_controls: null
      },
      prior_claims_experience_incidents: {
        during_past_three_years_has_insured_suffered_failure_computer_system_wrongful_disclosure_filled_claim_violation_privacy_regulation_etc: 'No',
        detail_separately_include_pending_prior_incident_event_litigation_details_of_relevant_facts: null,
        is_undersigned_individual_aware_circumstances_likely_give_ris_claim_under_coverage_insured_applying: 'No',
        undersigned_individual_aware_please_explain: null,
        additional_information: null
      },
      email_security_services: {
        office_365: 'No',
        gsuite: 'No',
        exchange: 'No',
        other: 'No',
        allow_access_via_web: 'No',
        mfa: false,
        email_maintain: 'No',
      },
      email_security_services_controls: {
        screen_attachments: 'No',
        screen_links: 'No',
        quarantine_suspicious: 'No',
        evaluate_attachments: 'No',
        tag_external: 'No',
        employees_trained_external_email: 'No',
        dkim: 'No',
        spf: 'No',
        dmarc: 'No',
        phishing_retrain: 'No',
        email_filtering: 'No',
        web_filtering: 'No',
        disable_macros: 'No',
        user_macros: 'No',
        insured_have_secure_email_gateway: 'No',
        what_tool_used: null,
        what_controls_place_to_filter_block_spam_emails_senders_attachments_explain: null,
        any_additional_information_pertaining_section: null,
        communications_phishing_attempts: 'No',
        phishing_security_awareness: 'No'
      },
      email_security_services_phishing: {
        phishing_training: 'No',
        phishing_simulations: 'No',
        phishing_email_simulations: 'No',
        communications_phishing_attempts: null,
        phishing_security_awareness: null,
        conduct_training_2x_year: 'No',
        formalized_training_not_provided_how_employees_educated_security_risks_organizational_policies: null
      },
      physical_location_infrastructure: {
        desktops: false,
        laptops: false,
        servers: false
      },
      remote_work_policy: {
        virtual_machines: false,
        desktops_laptops: false,
        mobile: false,
        servers: false
      },
      network_security: {
        allow_remote: 'No',
        mfa_privileged_account: 'No',
        local_admin: 'No',
        password_management: 'No'
      },
      network_security_capabilities: {
        edr: 'No',
        epp: 'No',
        mdr: 'No',
        siem: 'No',
        pam: 'No',
        soc: 'No',
        rdp: 'No',
        ngav: 'No'
      },
      network_security_capabilities_options: {
        pam_privileded: 'No',
        require_checkout_pwd: 'No',
        soc_authority: 'No',
        disallow_inbound_connections: 'No',
        rdp_mfa_enforced: 'No',
        inbound_communications_denied: 'No'
      },
      general_security_controls: {
        employees_least_privilege: 'No',
        network_level_stored: 'No',
        data_classification_policy: null,
        classified_tiers: null,
        powershell_microsoft: 'No',
        vendor_management_process: 'No',
        insured_accept_payment_cards_yes_ensure_point_encryption_card: 'No',
        insured_accept_payment_cards_goods_service: 'No'
      },
      on_premise_infrastructure: {
        databases: false,
        applications: false,
        dev_environments: false,
        servers: false
      },
      legacy_systems: {
        asset_discovery: 'No',
        asset_database: 'No',
        cmdb: 'No',
        eol_in_use: 'No',
        process_decommission: 'No'
      },
      cloud_based_applications_infrastructure: {
        saas: false,
        paas: false,
        iaas: false,
        saas_inventory: null,
        azure: null,
        gcp: null,
        aws: null,
        other: null,
        saas_mfa: null
      },
      backups_business_continuity: {
        on_prem: 'No',
        offsite: 'No',
        offline: 'No',
        cloud: 'No',
        describe_where_stored_offsite_backup: null,
      },
      backup_business_continuity_controls: {
        encrypted: 'No',
        mfa: 'No',
        credentials: 'No',
        malware: 'No',
        tested: 'No',
        immutable: 'No',
        retention: 'No',
        frequency: 'No',
        restore_time: 'No',
        offline_backup_copies: 'No',
        dr_plan: 'No',
        executed_plan: 'No',
        tested_incident_response_plan: 'No',
        tabletop: 'No',
        tabletop_ransomware: 'No'
      },
      service_accounts: {
        least_privilege: 'No',
        monitoring: 'No',
        deny_interactive: 'No',
        strong_passwords: 'No',
        rotate_passwords: 'No',
        pam_passwords: 'No'
      },
      vulnerabilities_scanning: {
        hardened_baseline: 'No',
        patching_extend_third_party_platforms: 'No'
      }
    },
    limitAdequacyRiskReports: null,
    threatAssessmentReports: null,
    isPreCheckSubmitted: false,
    hasMissingCompanyDetails: false,
    disableContinue: false,
    inviteTeamMemberDialog: false,
    submitUserForm: false
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    setCompanyDetails(state, data) {
      let companyDetails = data;
      if(!data.revenue) {
        companyDetails.revenue = state.companyRevenueDetails;
        state.hasMissingCompanyDetails = true;
      } else {
        state.companyRevenueDetails = data.revenue;
      }

      if(!data.professional_services_revenue) {
        companyDetails.professional_services_revenue = state.professionalServicesRevenue;
        state.hasMissingCompanyDetails = true;
      } else {
        let professionalServicesRevenue = Object.assign([], state.professionalServicesRevenue);
        professionalServicesRevenue.map((data, index) => {
          if(companyDetails.professional_services_revenue[index]) {
            professionalServicesRevenue[index] = companyDetails.professional_services_revenue[index]
          }
        })
        state.professionalServicesRevenue = professionalServicesRevenue;
      }

      if(!data.largest_clients_revenue) {
        companyDetails.largest_clients_revenue = state.largestClientsRevenue;
        state.hasMissingCompanyDetails = true;
      } else {
        state.largestClientsRevenue = companyDetails.largest_clients_revenue;
      }

      if(!data.contracts) {
        companyDetails.contracts = state.contracts;
        state.hasMissingCompanyDetails = true;
      } else {
        state.contracts = companyDetails.contracts;
      }

      if(data.technology_services_revenue) {
        let technologyServicesRevenue = Object.assign({}, state.technologyServicesRevenue);
        Object.keys(technologyServicesRevenue).map((key) => {
          technologyServicesRevenue[key] = data.technology_services_revenue[key]
        });
        state.technologyServicesRevenue = Object.assign({}, technologyServicesRevenue);
      } else {
        companyDetails.technology_services_revenue = state.technologyServicesRevenue;
        state.hasMissingCompanyDetails = true;
      }

      if(data.industry_segment_revenue) {
        let industrySegmentRevenue = Object.assign({}, state.industrySegmentRevenue);
        Object.keys(industrySegmentRevenue).map((key) => {
          industrySegmentRevenue[key] = data.industry_segment_revenue[key]
        });
        state.industrySegmentRevenue = Object.assign({}, industrySegmentRevenue);
      } else {
        companyDetails.industry_segment_revenue = state.industrySegmentRevenue;
        state.hasMissingCompanyDetails = true;
      }

      if(data.quality_control_process) {
        let qualityControlProcess = Object.assign({}, state.qualityControlProcess);
        Object.keys(qualityControlProcess).map((key) => {
          qualityControlProcess[key] = data.quality_control_process[key]
        });
        state.qualityControlProcess = Object.assign({}, qualityControlProcess);
      } else {
        companyDetails.quality_control_process = state.qualityControlProcess;
        state.hasMissingCompanyDetails = true;
      }

      state.companyDetails = Object.assign({}, companyDetails);
    }
  },
  actions: {
    /* Action that login in a user */
    async createOrganization({ commit, dispatch, state}, payload) {
      const { data } = await axios.post('/organization', payload);
      if (!data.success) {
        return;
      }
      if(data.organization) {
        commit('setState', { key: 'currentOrganizationId', value: data.organization.entity_id });
      }
      await dispatch('createQuestionnaire', {organization_id: state.currentOrganizationId, questionnaire: state.questionnaire});
      return data;
    },
    async updateOrganization({ commit, dispatch, state}, payload) {
      let entity_id = payload.entity_id || state.currentOrganizationId;
      delete payload.entity_id;
      let redirectScreen = payload.redirectScreen;
      delete payload.redirectScreen;
      if(!entity_id) { // if any case org id missed then re-fetch data for update details
        entity_id = await dispatch('getOrganizationId');
      }
      if(!entity_id) {
        return;
      }
      const { data } = await axios.patch(`/organization/${entity_id}`, payload);
      if (!data.success) {
        return;
      }
      if(data.organization) {
        commit('setState', { key: 'currentOrganizationId', value: data.organization.entity_id });
        // commit('setCompanyDetails', data.organization);
      }
      if(redirectScreen) {
        state.questionnaire.current_screen = 'revenue';
        await dispatch('updateQuestionnaire', {organization_id: state.currentOrganizationId, questionnaire: state.questionnaire});
      }
      return data;
    },
    async getOrganization({ commit, dispatch, state}, payload) {
      const { data } = await axios.get('/organization');
      if (!data.success) {
        return;
      }
      if(data.organizations && data.organizations.length) {
        commit('setCompanyDetails', data.organizations[0]);
        commit('setState', { key: 'currentOrganizationId', value: data.organizations[0].entity_id });
        if(state.hasMissingCompanyDetails) { // for update newly added keys for fix old users
          dispatch('updateOrganization', state.companyDetails)
        }
      }
      return data;
    },
    async getOrganizationId({ commit, dispatch, state}, payload) {
      const { data } = await axios.get('/organization');
      if (!data.success) {
        return;
      }
      if(data.organizations && data.organizations.length) {
        commit('setState', { key: 'currentOrganizationId', value: data.organizations[0].entity_id });
        return data.organizations[0].entity_id;
      }
      return;
    },
    async deleteOrganization({ commit, dispatch, state}, payload) {
      const { data } = await axios.delete('/organization/'+payload.organization_id);
      if (!data.success) {
        return;
      }
      commit('setState', { key: 'currentOrganizationId', value: null });
    },
    async createCyberSecurityOrganization({ commit, dispatch, state}, payload) {
      let organizationId = payload.organization_id;
      delete payload.organization_id;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.post(`/organization/${organizationId}/cyber_security`, payload);
      if (!data.success) {
        return;
      }
      if(data['cyber_security']) {
        commit('setState', { key: 'cyberOrganizationId', value: data['cyber_security'].entity_id });
      }
      // dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return data;
    },
    async deleteCyberSecurityOrganization({ commit, dispatch, state}, payload) {
      let organizationId = payload.organization_id;
      delete payload.organization_id;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.delete(`/organization/${organizationId}/cyber_security/${payload.cyber_organization_id}`);
      if (!data.success) {
        return;
      }
      commit('setState', { key: 'cyberOrganizationId', value: null });
    },
    async getCyberOrganizationDetails({ commit, dispatch }, organizationId) {
      let orgId = organizationId;
      if(!orgId) { // if any case org id missed then re-fetch data for update details
        orgId = await dispatch('getOrganizationId');
      }
      const { data } = await axios.get(`/organization/${orgId}/cyber_security`);
      if (!data.success) {
        return;
      }
      if(data['cyber_security'] && Object.keys(data['cyber_security']).length) {
        commit('setState', { key: 'cyberOrgDetails', value: data['cyber_security'] });
        commit('setState', { key: 'cyberOrganizationId', value: data['cyber_security'].entity_id });
      }
      return data;
    },
    async updateCyberSecurityOrganization({ commit, dispatch, state}, payload) {
      let redirectScreen = payload.redirectScreen;
      delete payload.redirectScreen;
      let organizationId = payload.organization_id;
      let cyberOrgId = payload.entity_id;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!cyberOrgId) { // if any case cyber org id missed then re-fetch data for update details
        let cyberOrgDetails = await dispatch('getCyberOrganizationDetails', organizationId);
        if(cyberOrgDetails && cyberOrgDetails?.cyber_security?.entity_id) {
          cyberOrgId = cyberOrgDetails.cyber_security.entity_id;
        } else {
          return;
        }
      }
      if(!organizationId || !cyberOrgId) {
        return;
      }
      const { data } = await axios.patch(`/organization/${organizationId}/cyber_security/${cyberOrgId}`, payload);
      if (!data.success) {
        return;
      }
      if(redirectScreen) {
        state.questionnaire.current_screen = 'email_security_services';
        await dispatch('updateQuestionnaire', {organization_id: organizationId, questionnaire: state.questionnaire});
      }
      return data;
    },
    async getScannedApplicationDetails({ commit, dispatch, state}, payload) {
      let orgId = payload;
      if(!orgId) { // if any case org id missed then re-fetch data for update details
        orgId = await dispatch('getOrganizationId');
      }
      const { data } = await axios.get(`/application/organization/${orgId}`);
      if (!data.success) {
        return;
      }
      if(data['application']) {
        commit('setState', { key: 'applicationScanRequest', value: data.application })
      }
      return data;
    },
    async updateScannedApplicationStatus({ commit, dispatch, state}, payload) {
      let requestId = payload.entity_id;
      let status = payload.status;
      const { data } = await axios.put(`/application/${requestId}/status/${status}`);
      if (!data.success) {
        return;
      }
      if(data['application']) {
        commit('setState', { key: 'applicationScanRequest', value: data.application })
      }
      return data;
    },
    async createQuestionnaire({ commit, dispatch, state}, payload) {
      let organizationId = payload.organization_id;
      delete payload.organization_id;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.post(`/organization/${organizationId}/questionnaire`, payload.questionnaire);
      if (!data.success) {
        return;
      }
      if(data['questionnaire']) {
        commit('setState', { key: 'questionnaire', value: data['questionnaire'] });
      }
      return data;
    },
    async updateQuestionnaire({ commit, dispatch, state}, payload) {
      let organizationId = payload.organization_id;
      let questionnaireId = payload.questionnaire.entity_id;
      delete payload.organization_id;

      let forceUpdateState = payload.questionnaire.forceUpdateState;
      delete payload.questionnaire.forceUpdateState;

      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }

      if(!organizationId) {
        return;
      }

      if(!questionnaireId) { // if any case org id missed then re-fetch data for update details
        let questionnaireDetails = await dispatch('getQuestionnaire', organizationId);
        if(questionnaireDetails && questionnaireDetails?.questionnaire?.entity_id) {
          questionnaireId = questionnaireDetails.questionnaire.entity_id;
        } else {
          return;
        }
      }

      const { data } = await axios.patch(`/organization/${organizationId}/questionnaire/${questionnaireId}`, payload.questionnaire);
      if (!data.success) {
        return;
      }
      if(data['questionnaire'] && forceUpdateState) {
        commit('setState', { key: 'questionnaire', value: data['questionnaire'] });
      }
      return data;
    },
    async deleteQuestionnaire({ commit, dispatch, state}, payload) {
      let organizationId = payload.organization_id;
      delete payload.organization_id;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.delete(`/organization/${organizationId}/questionnaire/${payload.questionnaireId}`);
      if (!data.success) {
        return;
      }
      if(data['questionnaire']) {
        commit('setState', { key: 'questionnaire', value: data['questionnaire'] });
      }
      return data;
    },
    async submitPreCheck({ commit, dispatch }, payload) {
      let organizationId = payload.organization_id;
      delete payload.organization_id;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.post(`/organization/${organizationId}/pre_check`, {
        status: 'submitted'
      });
      if (!data.success) {
        return;
      }
      commit('setState', { key: 'isPreCheckSubmitted', value: data.cyber_pre_check && (data.cyber_pre_check?.status?.toLowerCase() == 'submitted' || data.cyber_pre_check?.status?.toLowerCase() == 'completed') ? true : false });
      return data;
    },
    async getQuestionnaire({ commit, dispatch }, orgId) {
      let organizationId = orgId;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.get(`/organization/${organizationId}/questionnaire`);
      if (!data.success) {
        return;
      }
      if(data['questionnaire']?.entity_id) {
        if(!data['questionnaire']['media_content_confidential_data']) {
          data['questionnaire']['media_content_confidential_data'] = {}
        }
        if(!data['questionnaire']['e_crime_controls']) {
          data['questionnaire']['e_crime_controls'] = {}
        }
        if(!data['questionnaire']['prior_claims_experience_incidents']) {
          data['questionnaire']['prior_claims_experience_incidents'] = {}
        }
        commit('setState', { key: 'questionnaire', value: data['questionnaire'] });
      }
      return data;
    },
    async getRatingResults({ commit, dispatch }, orgId) {
      let organizationId = orgId;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.get(`/organization/${organizationId}/rating_results`);
      if (!data.success) {
        return;
      }
      commit('setState', { key: 'ratingResults', value: data.data });
      return data;
    },
    async getRatingRecommendations({ commit, dispatch }, orgId) {
      let organizationId = orgId;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.get(`/organization/${organizationId}/rating_results/recommendations`);
      if (!data.success) {
        return;
      }
      return data;
    },
    async downloadSupplementalForm({ commit, dispatch }, orgId) {
      let organizationId = orgId;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      await axios.get(`/organization/${organizationId}/generate_filled_form`, {
        responseType: 'blob'
      }).then(async (res) => {
        const textData = await res.data.text();
        try {
          const errorJson = JSON.parse(textData);
          if(errorJson && errorJson.success == false) {
            dispatch("showMessage", { message: errorJson.message, messageType: "error", messageTitle: "Error" }, { root: true })
            return;
          }
        } catch {

        }
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(res.data);
        a.download = `supplementalForm.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    },
    async getPreCheckStatus({ commit, dispatch }, orgId) {
      let organizationId = orgId;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.get(`/organization/${organizationId}/pre_check`, {
      })
      if (!data.success) {
        return;
      }
      commit('setState', { key: 'isPreCheckSubmitted', value: data.cyber_pre_check && (data.cyber_pre_check?.status?.toLowerCase() == 'submitted' || data.cyber_pre_check?.status?.toLowerCase() == 'completed') ? true : false });
      return data;
    },
    async getUserHasS2RAccount({ commit, dispatch}, email) {
      const { data } = await axios.post(`/readiness/is_user_exists`, {
        email: email
      })
      return data;
    },
    async getS2RAccessToken({ commit, dispatch}, email) {
      const { data } = await axios.post(`/readiness/get_access_token`, {
        email: email
      })
      return data;
    },
    async getLimitAdequacyRisk({ commit, dispatch}, orgId) {
      let organizationId = orgId;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.get(`/organization/${organizationId}/reports`);
      if (!data.success) {
        return;
      }
      commit('setState', { key: 'limitAdequacyRiskReports', value: data?.reports?.report_files?.risk_model });
      commit('setState', { key: 'threatAssessmentReports', value: data?.reports?.report_files?.threat_assesment });
    },
    async getShareCyberStatusWithBroker({ commit, dispatch }, organizationId) {
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.get(`/organization/${organizationId}/share_with_broker`)
      if (!data.success) {
        return;
      }
      return data;
    },
    async shareCyberStatusWithBroker({ commit, dispatch }, payload) {
      let formData = Object.assign({}, payload)
      let organizationId = formData.organizationId
      delete formData.organizationId;
      if(!organizationId) { // if any case org id missed then re-fetch data for update details
        organizationId = await dispatch('getOrganizationId');
      }
      if(!organizationId) {
        return;
      }
      const { data } = await axios.post(`/organization/${organizationId}/share_with_broker`, { status: 'submitted', broker: formData })
      if (!data.success) {
        return;
      }
      dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return data;
    },
    async inviteTeamMember({ commit, dispatch }, payload) {
      let formData = Object.assign({}, payload)
      const { data } = await axios.post(`/authentication/invite/client`, formData)
      if (!data.success) {
        return;
      }
      dispatch('showMessage', { ...data, messageType: 'success', messageTitle: 'Success' }, { root: true });
      return data;
    }
  },
  getters: {
    getField
  }
}
