<template>
  <v-footer width="100%" color="transparent">
    <v-col cols="12">
      <div class="text-center outfit-regular text-primary font-400 text-16" v-if="showBrokerLink">Are you a broker? <span class="cursor-pointer text-decoration-underline" @click="redirectToS2Broker">Click here</span></div>
      <div class="text-center outfit-regular text-primary font-300 text-16">Powered by <span class="cursor-pointer text-decoration-underline" @click="redirectToS2">SecondSight</span></div>
      <div v-if="!layoutView" class="text-right pa-2 outfit-regular text-16 font-400">
        <span class="footer-text-color text-decoration-underline cursor-pointer" @click="privacyPolicyDialog = true">Privacy Policy</span>
        <span class="footer-text-color" v-if="showVersion"> - Version {{ versionInfo }}</span>
      </div>
    </v-col>
    <privacy-policy :show-dialog="privacyPolicyDialog" @cancel-dialog="privacyPolicyDialog = false" />
  </v-footer>
</template>

<script>
import versionInfoMixin from '@/mixins/versionInfo';
import PrivacyPolicy from "@/components/Terms/PrivacyPolicy.vue";

export default {
  components: {PrivacyPolicy},
  mixins: [versionInfoMixin],
  props: ['layoutView', 'showBrokerLink'],
  data() {
    return {
      privacyPolicyDialog: false
    }
  },
  methods: {
    redirectToS2() {
      window.open("https://secondsight.ai", "second-sight-window");
    },
    redirectToS2Broker() {
      window.open("https://app.secondsight.ai/broker/signup", "second-sight-window")
    }
  }
}
</script>

<style lang="scss" scoped>
  .version-text {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .footer-text-color {
    color: #888787 !important;
  }
  .v-footer {
    align-items: flex-end !important;
  }
</style>
