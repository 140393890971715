<template>
  <v-dialog v-model="showDialog" @cancel-dialog="toggleDialog" :maxWidth="1040">
    <v-card>
      <v-card-text class="pt-6">
        <div class="text-right">
          <v-btn
            icon
            class="black--text terms-close-icon"
            @click="toggleDialog"
          >
            <v-icon size="16">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row class="pa-0 d-flex align-baseline text-justify ma-3">
          <v-col cols="12" md="12" class="pa-6 policy-container">
            <v-tabs v-model="activeTab" class="dark-slide-tabs secondary-tabs">
              <v-tabs-slider color="#39CA56"></v-tabs-slider>

              <v-tab class="px-8"> Privacy Policy </v-tab>
            </v-tabs>
            <div
              class="text-light-black font-400 outfit-regular text-14 pt-4 pr-4"
              style="max-height: 400px; overflow-y: auto"
            >
              <div class="d-flex justify-end pb-3">
                VERSION DATED MAY 24, 2022
              </div>

              <p>
                This Privacy Policy ("Privacy Policy”) explains our privacy
                practices for the activities described herein. We respect your
                privacy and are committed to protecting it through our
                compliance with this Privacy Policy. Please read this Privacy
                Policy carefully to learn how we collect, use, share, and
                otherwise process information relating to individuals ("Personal
                Data”), and to learn about your rights and choices regarding
                your Personal Data.
              </p>
              <p>
                A reference to "we,” "us” or "our” is a reference to Second
                Sight Data Discovery, Inc and its relevant subsidiaries or
                affiliates involved in the collection, use, sharing, or other
                processing of Personal Data.
              </p>
              <ol type="a">
                <li class="pl-3">
                  Responsible Entity – We are the controller of your Personal
                  Data as described in this Privacy Policy, unless specified
                  otherwise.
                </li>
                <li>
                  Processing Activities Covered – This Privacy Policy applies to
                  the processing of Personal Data collected by us when you visit
                  us on the web or through social media pages; receive
                  communications from us, including emails, phone calls, texts
                  or fax; attend one of our events; use our software or engage
                  us to provide services to you.
                </li>
              </ol>
              <p class="pt-2">
                Our website, software, and services may contain links to other
                websites, applications, and services maintained by third
                parties. The information practices of other services, or of
                social media platforms that host our branded social media pages,
                are governed by their Privacy Policies, which you should review
                to better understand their privacy practices.
              </p>
              <ol class="mt-4">
                <li>
                  Personal Data Collected – The Personal Data we collect
                  directly from you may include identifiers, professional or
                  employment-related information, financial account information,
                  commercial information, visual information, and internet
                  activity information. We collect such information in the
                  following situations:
                  <ul type="disc" class="mt-4">
                    <li>
                      If you express an interest in obtaining additional
                      information about our software or services; request
                      customer support; use our "Contact Us” or similar
                      features; sign up for and/or attend an event or webinar;
                      download certain content.
                    </li>
                    <li>
                      If you purchase a subscription for our software or
                      register for a webinar or event, we may require that you
                      provide to us your financial and billing information, such
                      as billing name and address, credit card number or bank
                      account information.
                    </li>
                    <li>
                      If you interact with our website or emails, we may
                      automatically collect information about your device and
                      your usage of our website or emails, IP addresses or other
                      identifiers, which may qualify as Personal Data using
                      cookies, web beacons, or similar technologies. A cookie is
                      a small file placed on the hard drive of your computer.
                      You may refuse to accept browser cookies by activating the
                      appropriate setting on your browser. However, if you
                      select this setting you may be unable to access certain
                      parts of our Website. Unless you have adjusted your
                      browser setting so that it will refuse cookies, our system
                      will issue cookies when you direct your browser to our
                      Website. Certain features of our Website may use local
                      stored objects (or Flash cookies) to collect and store
                      information about your preferences and navigation to,
                      from, and on our Website. Flash cookies are not managed by
                      the same browser settings as are used for browser cookies.
                      Pages of our Website and our e-mails may contain small
                      electronic files known as web beacons (also referred to as
                      clear gifs, pixel tags, and single-pixel gifs) that permit
                      us, for example, to count users who have visited those
                      pages or opened an email and for other related website
                      statistics (for example, recording the popularity of
                      certain website content and verifying system and server
                      integrity).
                    </li>
                    <li>
                      If you use and interact with our software or services, we
                      may automatically collect information about your device
                      and your usage of our services through log files and other
                      technologies, some of which may qualify as Personal Data.
                    </li>
                    <li>
                      If you voluntarily submit certain information to our
                      software or services, such as filling out a survey about
                      your user experience, we collect the information you have
                      provided as part of that request.
                    </li>
                    <li>
                      If you provide us or our service providers with any
                      Personal Data relating to other individuals, you represent
                      that you have the authority to do so, and where required,
                      have obtained the necessary consent, and acknowledge that
                      it may be used in accordance with this Privacy Policy.
                    </li>
                  </ul>
                </li>
                <li>
                  If you believe that your Personal Data has been provided to us
                  improperly, or if you want to exercise your rights relating to
                  your Personal Data, please contact us immediately.
                </li>
                <li>
                  Using Personal Data – We may collect and process your Personal
                  Data for a variety of purposes. Where required by law, we
                  obtain your consent to use and process your Personal Data for
                  these purposes. Otherwise, we may rely on the need to use such
                  data to perform a contract or other legal basis. Examples of
                  such reasons include, but are not limited to, the following:
                  <ul type="disc" class="mt-4">
                    <li>
                      Promoting the security of our website, software, and
                      services. We may process your Personal Data by tracking
                      use of our website, software, and services, creating
                      aggregated non-personal data, verifying accounts and
                      activity, investigating suspicious activity, and enforcing
                      our terms and policies to the extent it is necessary for
                      our legitimate interest in promoting the safety and
                      security of the services, systems and applications and in
                      protecting our rights and the rights of others.
                    </li>
                    <li>
                      Reviewing compliance with applicable usage terms. We may
                      process your Personal Data to review compliance with the
                      applicable usage terms in your applicable EULA, MPSA, or
                      other such agreement.
                    </li>
                    <li>
                      Handling contact and user support requests. If you fill
                      out a "Contact Me” web form or request user support, or if
                      you contact us by other means including via a phone call,
                      we may process your Personal Data to address your issue.
                    </li>
                    <li>
                      Providing necessary functionality. We may process your
                      Personal Data to perform our contract with you for the use
                      of our website, software, and services; if we have not
                      entered into a contract with you, we base the processing
                      of your Personal Data on our legitimate interest to
                      provide you with the necessary functionality required for
                      your use of our websites and services.
                    </li>
                    <li>
                      Managing payments. If you have provided financial
                      information to us, we may process your Personal Data to
                      verify that information and to collect payments to the
                      extent that doing so is necessary to complete a
                      transaction and perform our contract with you.
                    </li>
                    <li>
                      Providing, developing and improving our website, software,
                      and services. We may process your Personal Data to perform
                      obligations set forth in any applicable EULA, MPSA or
                      similar agreement we enter into with you, or for the use
                      of our website, software and services. If we have not
                      entered into a contract with you, we may process your
                      Personal Data to operate and administer our website,
                      software, and services, and to provide you with content
                      you access or request. Such information may also be used
                      to improve our website, software, and services, and to
                      improve functionality.
                    </li>
                    <li>
                      Managing event registrations and attendance. We may
                      process your Personal Data to plan and host events or
                      webinars for which you have registered or that you attend,
                      including sending related communications to you.
                    </li>
                    <li>
                      Assessing opportunities. We may process your Personal Data
                      to assess the requirements of our software and services to
                      ensure that we are meeting the necessary requirements of
                      our service offering and recommending the correct level of
                      engagement.
                    </li>
                    <li>
                      Sending marketing communications. We may process your
                      Personal Data or device and usage data, which in some
                      cases may be associated with your Personal Data, to send
                      you marketing information, product recommendations and
                      other non-transactional communications about us, our
                      subsidiaries, and our affiliates and partners, including
                      information about our products or events.
                    </li>
                  </ul>
                </li>
                <li>
                  If we need to collect and process Personal Data by law, or
                  under a contract we have entered into with you, and you fail
                  to provide the required Personal Data when requested, we may
                  not be able to perform our contract with you.
                </li>
                <li>
                  Sharing Your Personal Data – While we generally strive to keep
                  your Personal Data private, we may share your Personal Data as
                  necessary for reasons such as those set forth below:
                  <ul type="disc" class="mt-4">
                    <li>
                      To fulfill the purpose for which you provide it. For
                      example, if you request a specialized cyber insurance
                      quote based on the results of our software, we will
                      transmit the Personal Data necessary for the quote to be
                      provided.
                    </li>
                    <li>
                      With our contracted service providers, who provide
                      services such as IT and system administration and hosting,
                      credit card processing, research and analytics, data
                      analysis, marketing, customer support and data enrichment
                      for the purposes set forth above and to fulfill any
                      agreements we may have with you.
                    </li>
                    <li>
                      If you use our website to register for an event or webinar
                      organized by us or one of our affiliates, we may share
                      your Personal Data with any event sponsors as well as any
                      affiliate to the extent this is required on the basis of
                      the affiliate’s contract with you to process your
                      registration and ensure your participation in the event.
                    </li>
                    <li>
                      We may share your Personal Data with professional advisors
                      acting as service providers, processors, or joint
                      controllers – including, but not limited to, lawyers,
                      bankers, accountants, auditors, and insurers based in
                      countries in which we operate who provide consultancy,
                      banking, legal, insurance and accounting services, and to
                      the extent we are legally obliged to share or have a
                      legitimate interest in sharing your Personal Data.
                    </li>
                    <li>
                      We may share anonymized and aggregated data in the normal
                      course of operating our business; for example, we may
                      share information publicly to show trends about the
                      general use of our services or usage patterns and best
                      practices within a particular industry. We may also share
                      such information with our subsidiaries and affiliates.
                    </li>
                    <li>
                      If we are involved in a merger, reorganization,
                      dissolution or other fundamental corporate change, or sell
                      a business unit, or if all or a portion of our business,
                      assets are acquired by a third party, with such third
                      party. In accordance with applicable laws, we will use
                      reasonable efforts to notify you of any transfer of
                      Personal Data to an unaffiliated third party.
                    </li>
                    <li>
                      To comply with any court order, law, or legal process,
                      including to respond to any government or regulatory
                      request.
                    </li>
                  </ul>
                </li>
                <li>
                  International transfer of Personal Data – Your Personal Data
                  may be collected, processed, transferred to and stored by us
                  outside your jurisdiction, and in countries that are not
                  subject to an adequacy decision by the European Commission or
                  your local legislature or regulator, and that may not provide
                  for the same level of data protection as your jurisdiction,
                  such as the EEA. We ensure that the recipient of your Personal
                  Data offers an adequate level of protection and security, for
                  instance by entering into the appropriate back-to-back
                  agreements and, if required, standard contractual clauses for
                  the transfer of data as approved by the European Commission
                  (Art. 46 GDPR). Where required by applicable law, we will only
                  share, transfer or store your Personal Data outside of your
                  jurisdiction with your prior consent.
                </li>
                <li>
                  Personal Data Retention – We may retain your Personal Data for
                  a period of time consistent with the original purpose of
                  collection or as long as required to fulfill our legal
                  obligations. We determine the appropriate retention period for
                  Personal Data on the basis of the amount, nature, and
                  sensitivity of the Personal Data being processed, the
                  potential risk of harm from unauthorized use or disclosure of
                  the Personal Data, whether we can achieve the purposes of the
                  processing through other means, and on the basis of applicable
                  legal requirements.
                </li>
                <li>
                  Rights Relating to Your Personal Data – You may have certain
                  rights relating to your Personal Data, subject to local data
                  protection laws. To exercise your rights, please contact us by
                  using the information in the "Contact us” section, below. We
                  try to respond to all legitimate requests within one month and
                  will contact you if we need additional information from you in
                  order to honor your request. Occasionally it may take us
                  longer than a month, taking into account the complexity and
                  number of requests we receive.
                </li>
                <li>
                  Data Security – We take appropriate precautions including
                  organizational, technical, and physical measures to help
                  safeguard against accidental or unlawful destruction, loss,
                  alteration, and unauthorized disclosure of, or access to, the
                  Personal Data we process or use. However, the safety and
                  security of your information also depends on you. Where we
                  have given you (or where you have chosen) a password for
                  access to certain parts of our Website, you are responsible
                  for keeping this password confidential.While we follow
                  generally accepted standards to protect Personal Data, no
                  method of storage or transmission is 100% secure. Any
                  transmission of personal information is at your own risk. We
                  are not responsible for circumvention of any privacy settings
                  or security measures contained on the website.
                </li>
                <li>
                  Changes to this Privacy Policy – It is our policy to post any
                  changes we make to our privacy policy on this page and to
                  update the "effective date” at the top of the page when we do.
                  If we make material changes to how we treat our users’
                  personal information, we may notify you prior to the update
                  taking effect or may post a conspicuous notice on our website
                  of the change. You are responsible for ensuring we have an
                  up-to-date active and deliverable email address for you, and
                  for periodically visiting our website and this privacy policy
                  to check for any changes.
                </li>
                <li>
                  Contact – To exercise your rights regarding your Personal
                  Data, or if you have questions regarding this Privacy Policy
                  or our privacy practices, please email us at
                  <a href="mailto:privacy@secondsight.ai"
                    >privacy@secondsight.ai</a
                  >.
                </li>
                <li>
                  Children – Our website is not intended for children under 16
                  years of age and we do not knowingly collect Personal Data
                  from children under the age of 16. If you believe we might
                  have any information from or about a child under 16, please
                  contact us at the address set forth below.
                </li>
                <li>
                  Additional Disclosures for California Residents – If you are a
                  California resident, California law may provide you with
                  additional rights regarding our use of your personal
                  information. California’s "Shine the Light” law (Civil Code
                  Section § 1798.83) permits users of our software that are
                  California residents to request certain information regarding
                  our disclosure of personal information to third parties for
                  their direct marketing purposes. To make such a request,
                  please send an email to privacy@secondsight.ai or write us at:
                  509 S. Woodscrest Drive, Bloomington, IN 47401. If you are an
                  authorized agent wishing to exercise rights on behalf of a
                  California resident, please provide us with a copy of the
                  consumer’s written authorization designating you as their
                  agent. We may need to verify your identity and place of
                  residence before completing your rights request.The California
                  Consumer Privacy Act requires businesses to disclose whether
                  they sell Personal Data. To the extent we may be covered by
                  the CCPA, we do not sell Personal Data. We may share Personal
                  Data with third parties or allow them to collect Personal Data
                  from our sites or Services if those third parties are
                  authorized service providers or business partners who have
                  agreed to our contractual limitations as to their retention,
                  use, and disclosure of such Personal Data.California law
                  requires that we detail the categories of Personal Data that
                  we share or disclose for certain "business purposes,” such as
                  disclosures to service providers that assist us with securing
                  our services or marketing our products. We disclose the
                  following categories of Personal Data for our business
                  purposes: identifiers; commercial information; internet
                  activity information; financial information; professional and
                  employment-related information; education information; and
                  inferences drawn from any of the above information categories.
                </li>
              </ol>
            </div>

            <div class="text-right mt-3">
              <v-btn
                outlined
                rounded
                class="outfit-bold text-12 font-700 light-grey-outlined"
                @click="toggleDialog"
              >
                Ok
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["showDialog"],
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    toggleDialog() {
      this.$emit("cancel-dialog");
    },
  },
};
</script>


<style scoped lang="scss">
ol {
  li {
    margin-top: 12px;
    padding-left: 8px;
  }
}
.policy-container {
  background: #ffffff;
  border: 1px solid rgba(17, 16, 16, 0.08);
  box-shadow: 0px 8px 40px rgba(17, 16, 16, 0.04);
  border-radius: 16px;
  padding: 40px;
  min-height: 200px;
  z-index: 1;
}
.terms-close-icon {
  right: 6px;
  width: 32px;
  height: 32px;
  background: #f0efef;
  top: 16px;
  position: absolute;
}
</style>