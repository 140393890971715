import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
export default {
  data: () => ({
    requiredKeys: [
      'organisationName',
      'addressLine1',
      'postalCode',
      'businessStructure',
      'businessDescription',
      'naicsCode',
      'noOfEmployees',
      'fullYearRevenue'
    ]
  }),
  computed: {
    checkEmail() {
      return (value) => {
        return isEmail(value);
      }
    }
  },
  methods: {
    isFormComplete(form) {
      const errors = [];
      for (let key in form) {
        if (this.requiredKeys.includes(key)) {
          if (form[key] === '') {
            errors.push(`${key.toLowerCase()} is required`)
          }
        }
      }
      return {
        valid: errors.length ? false : true,
        errors
      }
    },
    validURL(str) {
      return isURL(str);
    }
  },
}