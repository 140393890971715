<template>
  <v-container fluid>
    <v-row class="vh-100">
      <v-col cols="12" md="12" class="d-flex align-center justify-center">
        <v-row class="px-14 pt-10 pb-4 signup-form-container">
          <v-col cols="12" md="12" class="text-center">
            <brand :maxHeight="'64px'"/>
          </v-col>
          <v-col cols="12" md="12">
            <h2 class="gt-extended-bold text-center text-50">
              <span>{{ view === 'login' ? 'Login' : 'Forgot Password' }}</span>
            </h2>
          </v-col>
          <v-col cols="12" md="12" class="px-6 pb-0">
            <login-form />
          </v-col>
          <v-col cols="12" v-if="view == 'login'">
            <div class="my-4 outfit-bold text-23 text-center">Or</div>
            <div class="d-flex align-center justify-space-between">
              <div>Don't have an account? 
                <span
                  @click="handleLinkClick('signup')"
                  class="ml-2 cursor-pointer text-capitalize font-weight-bold text-primary"
                >
                  Create account
                </span>
              </div>
              <div>
                <span class="cursor-pointer text-capitalize font-weight-bold text-primary" @click="handleLinkClick">Forgot Password?</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" v-if="view == 'forgot'" class="text-right">
            <div class="cursor-pointer text-capitalize font-weight-bold text-primary mt-4" @click="handleLinkClick">Back to login</div>
          </v-col>
        </v-row>
      </v-col>
      <app-footer :showBrokerLink="true" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import AppFooter from '@/components/Base/AppFooter'
import validationMixin from '@/mixins/validation'
import appInfoMixin from '@/mixins/appInfo'
import LoginForm from '@/components/Base/LoginForm'
import Brand from "@/components/Base/Brand.vue";

export default {
  mixins: [validationMixin, appInfoMixin],
  components: {
    Brand,
    AppFooter,
    LoginForm
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters("login", [
      'isLoading'
    ]),
    ...mapFields("login", [
      'view',
      'email',
      'password',
      'passwordType',
      'mfa_dialog',
      'twoFactor',
      'recoveryCodeForm',
      'authCode',
      'recoveryCode'
    ])
  },
  methods: {
    loginUsingMfa() {
      if (this.$refs.twoFactorForm.validate()) {
        this.$store.dispatch('login/loginMfa', { email: this.email, otp: this.authCode, password: this.password })
      }
    },
    loginUsingRecoveryCode() {
      if (this.$refs.recoveryCodeForm.validate()) {
        this.$store.dispatch('login/loginUsingRecovery', { email: this.email, recovery_code: this.recoveryCode, password: this.password })
      }
    },
    handleLinkClick(page) {
      if(page && page == 'signup') {
        this.$router.push({path: '/signup'})
      } else {
        if (this.view === 'login') {
          return this.forgotPassword()
        }
        this.showLogin();
      }
    },
    forgotPassword() {
      this.view = 'forgot'
      this.handleRouteData()
    },
    showLogin() {
      this.view = 'login'
      this.handleRouteData()
    },
    handleRouteData() {
      let path = `${this.$route.path}?view=${this.view}`
      this.$router.replace({ path })
    },
    checkViewInUrl() {
      if (this.$route.query && this.$route.query.view === 'forgot') {
        return this.view = 'forgot'
      } return this.view = 'login'
    }
  },
  mounted() {
    this.checkViewInUrl();
  }
}
</script>
